.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 55vh;
}

.bio {
  max-width: 760px;
  text-align: center;
  padding: 20px;
  font-size: 25px;
  font-family: sans-serif;
}

Footer {
  position: absolute;
  bottom: 0; 
  width: 100%; 
  padding: 10px; 
}