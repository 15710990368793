
.footer {
	background-color: #f4f4f4;
	padding: 20px 0;
	display: flex;
	flex-wrap: wrap; 
	justify-content: space-between;
	position: relative;
  	left: 0;
  	bottom: 0;
  	width: 100%;
  }
  
  .footer-column {
	flex: 1;
	padding: 0;
	max-width: 300px;
	box-sizing: border-box;
	bottom: 0;
  }

  .footer h3 {
	margin-bottom: 10px;
	margin-left: 0px;
  }
  
  .footer p,
  .footer ul {
	font-size: 14px;
  }
  
  .footer ul {
	list-style: none;
	padding: 0;
	margin: 0;
  }
  
  .footer ul li {
	margin-bottom: 5px;
	margin-left: 20px;
  }
  
  .footer a {
	text-decoration: none;
	color: #333;
  }
  
  .footer a:hover {
	color: gray;
  }

  .social-icons {
	list-style: none;
	display: flex;
	padding: 0;
  }
  
  .social-icons li {
	margin-right: 10px;
  }
  
  .social-icons a {
	text-decoration: none;
	color: #333;
	font-size: 24px;
  }