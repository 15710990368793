
.body {
    background-color:  rgb(31, 54, 61);
    height: 100vh;
    min-height: 100vh;
}

.h1 {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: white; 
    background-color: rgb(31, 54, 61);

}
.card-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    min-height: 100vh;
    padding: 60px;
    background-color: rgb(31, 54, 61);
  }

  
.card {
    display: flex; 
    flex-direction: column;
    color: white; 
    background-color:  rgb(31, 54, 61);
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 40px;
    margin-left: 60px;
    margin-right: 40px;
    height: 300px; 
    width: 100%;
    max-width: 250px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(246, 247, 244, 1.9);
    }
  

    .sig {
      align-items: center;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-left: 260px;
      margin-right: 260px;
      background-color: rgb(31, 54, 61);

    }
